import React from "react";
import {Box, Grid, TextField, Typography, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ConfirmDeleteDialog} from "../../shared/ConfirmDeleteDialog";
import {useTranslation} from "react-i18next";
import {
    CancelOutlined
} from "@mui/icons-material";
import {OrderItem} from "../../../models/order/OrderItem";
import UnitSelect from "../../unit/UnitSelect";
import {IUnitLocale} from "../../../interfaces/unit/IUnitLocale";


interface IProps {
    product: OrderItem,
    onDelete(id: number): void,
    onChange(index:number, p:OrderItem): void
    index: number,

}

export const OrderFormitem: React.FC<IProps> = (props) => {

    const {t} = useTranslation();
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);


    const handlePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = !Number.isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : 0;

        props.product.sellPrice = Number(value);
        props.onChange(props.index, props.product);
    }

    const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = !Number.isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : 0;
        props.product.amount = Number(value);
        props.onChange(props.index, props.product);
    }

    const theme = useTheme();



    return (
        <Box  sx={{backgroundColor: theme.palette.grey[200], padding: theme.spacing(1,), borderRadius: 2, marginBottom: theme.spacing(1)}} >
            <Grid  container direction={"row"}  alignItems={'center'} >
                <Grid item display="flex"  alignItems="center" xs={4} >
                    <Box mr={0.4}>
                        <Typography color="primary" variant="body2">{(props.index + 1)}</Typography>
                    </Box>
                    <Box mr={3}>
                        <Typography color="black" variant="body2">{props.product.fullCode}</Typography>
                        <Typography color="black" variant="body2">{props.product.name}</Typography>
                    </Box>
                </Grid>

                <Grid item  display="flex" alignItems="center" xs={8}>

                    <Box mr={1}>
                        <TextField  sx={{maxWidth: 60}} size="small" label={"Sklad"} type={"number"} variant="standard" disabled={true} defaultValue={0} InputLabelProps={{
                            shrink: true
                        }}></TextField>
                    </Box>
                    <Box mr={0.5}>
                        <TextField InputLabelProps={{
                            shrink: true
                        }} sx={{maxWidth: 80}} size="small" variant="standard" label={"Množství"} value={props.product.amount} type={"number"} onChange={handleAmount}></TextField>
                    </Box>
                    <Box mr={1}>
                        <Box mr={1} sx={{maxWidth: 80}}>
                            <Typography sx={{marginBottom: 0.5, display: 'block'}} variant="caption">MJ</Typography>
                            <Typography variant="body1">{props.product.unitLocale.code}</Typography>
                        </Box>
                    </Box>
                    <Box mr={1}>
                        <TextField  InputLabelProps={{
                            shrink: true
                        }} sx={{maxWidth: 150}} size="small" variant="standard" label={"Cena MJ bez DPH"} type={"number"} value={props.product.sellPrice} onChange={handlePrice}></TextField>
                    </Box>

                    <Box mr={1} sx={{maxWidth: 120}}>
                        <Typography sx={{marginBottom: 0.5, display: 'block'}} variant="caption">Celkem b DPH</Typography>
                        <Typography variant="body1">{props.product.sumSellPriceFixedDigits}</Typography>
                    </Box>
                    <Box mr={1} sx={{maxWidth: 80}}>
                        <Typography sx={{marginBottom: 0.5, display: 'block'}} variant="caption">DPH</Typography>
                        <Typography variant="body1">{props.product.sellVat}</Typography>
                    </Box>
                    <Box mr={1} sx={{maxWidth: 120}}>
                        <Typography sx={{marginBottom: 0.5, display: 'block'}} variant="caption">Celkem s DPH</Typography>
                        <Typography variant="body1">{props.product.sumSellPriceVatFixedDigits}</Typography>
                    </Box>
                    <Box>
                        <IconButton aria-label="delete" size="small" color={'error'} onClick={() => {
                            setConfirmDialogOpen(true)
                        }}>
                            <CancelOutlined />
                        </IconButton>
                    </Box>


                </Grid>



                <Grid item xs={1} textAlign={'right'}>


                </Grid>


                <ConfirmDeleteDialog message={t('orders.form.delete')} isOpen={confirmDialogOpen}
                                     onClose={() => setConfirmDialogOpen(false)} onConfirm={() => {
                    props.onDelete(props.index);
                    setConfirmDialogOpen(false);
                }
                }/>


            </Grid>
        </Box>

    )

}