import {NullableString} from "../../interfaces/NullableStringType";
import {IOrderErpConstructor} from "../../interfaces/order/IOrderErpConstructor";
import dayjs from "dayjs";

export class OrderErp {
    orderId:number
    erpId:number
    orderIdInErp:number
    orderNoInErp:string
    price:number
    updatedAt:string
    orderStateTextInErp: NullableString
    itemsInOrder:number

    constructor(j:IOrderErpConstructor) {
        this.orderId = j.orderId;
        this.erpId = j.erpId;
        this.orderIdInErp = j.orderIdInErp;
        this.orderNoInErp = j.orderNoInErp;
        this.price = j.price;
        this.updatedAt = j.updatedAt ? dayjs(j.updatedAt).format('DD.MM.YYYY HH:mm')  : "";
        this.orderStateTextInErp = j.orderStateTextInErp;
        this.itemsInOrder = j.itemsInOrder;
    }
}