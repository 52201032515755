import React from "react";
import {useTranslation} from "react-i18next";
import {Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";



interface IProps{
    onSingleChange: (id:number) => void
    onMultipleChange: (ids:number[]) => void
    singleId: number
    multipleIds: number[]
    disabled: boolean
    multiple: boolean
    chip: boolean
    chipVariant?: 'filled'|'outlined'
}

export const ProductState:React.FC<IProps> = (props) => {


    const {t} = useTranslation();

    //TODO: casem aplikovat Redux a z db vycitat stavy
    const dbStates = [
        {id: 1, name: 'Aktivní'},
        {id: 2, name: 'Neaktivní'},
        {id: 3, name: 'Přípravný'},

    ];

    const state = dbStates.find(x => x.id === props.singleId) ?? {id: 0, name: 'N/A'};
    const chipColor = (() => {
        switch (state.id) {
            case 1:
                return "success";
            case 2:
                return "error";
            case 3:
                return "info";
            default:
                return "primary";
        }
    })();

    const handleSingleChange = (val:string|number) => {
        props.onSingleChange(Number(val));

    }

    const handleMultipleChange = (val: unknown) => {
        props.onMultipleChange(val as number[]);
    }

    const label = t('products.newProductDialog.state');

    if(props.multiple){
        return(
            <FormControl fullWidth={true}>
                <InputLabel id="state-select-label">{t('products.newProductDialog.state')}</InputLabel>
                <Select
                    disabled={props.disabled}
                    variant="outlined"
                    fullWidth={true}
                    size="small"
                    labelId="state-select-label"
                    id="state-select"
                    label={label}
                    value={props.multipleIds}
                    multiple={true}
                    input={<OutlinedInput label="Tag" />}
                    onChange={(e) => handleMultipleChange(e.target.value )}
                >
                    {dbStates.map((x,i) => {
                        return(
                        <MenuItem key={'state_' + i} value={x.id}>
                            {x.name}
                        </MenuItem>
                        )
                    })}

                </Select>
            </FormControl>
        );
    }

    if(props.chip){
        return(
            <Chip variant={props.chipVariant ?? 'filled'} label={state.name} color={chipColor} size="small" />
        )
    }

    return(
        <FormControl>
            <InputLabel id="state-select-label">{t('products.newProductDialog.state')}</InputLabel>
            <Select
                disabled={props.disabled}
                variant="standard"
                size="small"
                labelId="state-select-label"
                id="state-select"
                label={label}
                value={props.singleId}
                onChange={(e) => handleSingleChange(e.target.value)}
            >
                {dbStates.map((x,i) => {
                    return(
                        <MenuItem key={'state_' + i} value={x.id}>{x.name} </MenuItem>
                    )
                })}

            </Select>
        </FormControl>
    );
}