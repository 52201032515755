import {OrderErpLastSync} from "../../models/order/OrderErpLastSync";
import {Alert, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

interface IProps{
    lastSync: OrderErpLastSync
}

export const OrderDetailSyncBox: React.FC<IProps> = ({lastSync}) => {
    const [showMore, setShowMore] = React.useState<boolean>(false);
    const {t} = useTranslation();
    const from = lastSync.direction === "in" ? "ERP" : "Intranet";
    const to = lastSync.direction === "in" ? "Intranet" : "ERP";
    let message = lastSync.isSuccess ? t('products.productDetailDialog.erp.lastSyncSuccess') : t('products.productDetailDialog.erp.lastSyncError');
    if(lastSync.direction === 'out' && lastSync.isSuccess) {
        message = 'Objednávka byla úspešně přenesena do ERP ke zpracování.'
    } else if(lastSync.direction === 'out' && !lastSync.isSuccess) {
        message = 'Objednávku se nepovedlo přenést do ERP.'
    }
    return (
        <Alert sx={{marginTop: 1}} severity={lastSync.isSuccess ? 'success' : 'error'}>
            <div> {from} {' ---> '} {to} | {t('general.date')}: {lastSync.lastSyncDate}</div>
            {!lastSync.isSuccess ?
                <div>
                    <div><Typography variant={'body1'}>{message}<span style={{cursor: 'pointer', color: 'gray'}} onClick={() => setShowMore(!showMore)}>{t('general.showMore')}</span></Typography></div>
                </div>
                : <div><Typography variant={'body1'}>{message}</Typography></div>}

            {!lastSync.isSuccess && showMore ?
                <div>{lastSync.message}</div>
                : <div></div>}
        </Alert>
    );
}