import {NullableString} from "../../interfaces/NullableStringType";
import {IOrderErpLastSyncConstructor} from "../../interfaces/order/IOrderErpLastSyncConstructor";
import dayjs from "dayjs";

export class OrderErpLastSync {
    orderId:number
    erpId:number
    direction:string
    isSuccess:boolean
    lastSyncDate: string
    message: NullableString

    constructor(j:IOrderErpLastSyncConstructor){
        this.orderId = j.orderId;
        this.erpId = j.erpId;
        this.direction = j.direction;
        this.isSuccess = j.isSuccess;
        this.lastSyncDate =  j.lastSyncDate ? dayjs(j.lastSyncDate).format('DD.MM.YYYY HH:mm')  : "";
        this.message = j.message;
    }
}