import React from "react";
import {Box, Grid, TextField} from "@mui/material";
import {ButtonBasic} from "../shared/ButtonBasic";
import {useTranslation} from "react-i18next";
import orderService from "../../services/OrderService";
import {IOrderFilter} from "../../interfaces/order/IOrderFilter";
import {CustomerSelect} from "../shared/CustomerSelect";
import {OrderStateSelect} from "./OrderStateSelect";
import {PaperBasic} from "../shared/PaperBasic";
import {OrdersFilterTable} from "./OrdersFilterTable";
import {Order} from "../../models/order/Order";
import {OrderDetailDialog} from "./OrderDetailDialog";
import {UsersSelect} from "../user/UsersSelect";
import {ErpStoreSelect} from "../erpStore/ErpStoreSelect";
import {IErpStore} from "../../interfaces/erpStore/IErpStore";


interface IProps{
    onEditOrder: (id:number) => void
}

export const OrdersFilter: React.FC<IProps> = (props) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    //todo: O vytvorit userCOntext
    const initialFilter: IOrderFilter = {
        orderNumber: null,
        createdFrom: null,
        createdTo: null,
        customerId: null,
        regionId: 1,
        storeId: null,
        stateIds: [],
        userId: null
    }
    const [form, setForm] = React.useState<IOrderFilter>(initialFilter);
    const [rows, setRows] = React.useState<Order[]>([]);
    const [selectedOrderId, setSelectedOrderId] = React.useState<number|null>(null);
    const [isDetailOpen, setDetailOpen] = React.useState(false);
    const [selectedStore, setSelectedStore] = React.useState<IErpStore|null>(null);


    const handleFilter = () => {
        setIsLoading(true);
        if(selectedStore) form.storeId = selectedStore.id;

        orderService.filter(form).then((res) => {
            setRows(res);

        }).catch(() => {

        }).finally(() => setIsLoading(false));
    }

    const handelDetailClick = (id:number) => {
        setSelectedOrderId(id);
        setDetailOpen(true);
    }

    const handleFormChange = (key: keyof IOrderFilter, value:  null|string|number|number[]) => {
        let f = {...form};
        f[key] = value;
        setForm(f);

    }

    //todo: O dodelat select skladu na filtr

    return(
        <>
        <PaperBasic title={t('orders.filter.title')} paperMargins={'20px 0px 30px 0px'}>
        <Grid container spacing={3} p={3}>
            <Grid item xs={12} sm={12} md={8} display="flex">
                    <CustomerSelect size="small"   onSelect={(item) => handleFormChange('customerId', item.length === 0 ? null : item[0].id)} isMultiple={false} />

            </Grid>
            <Grid item xs={12} sm={12} md={4} display="flex">
                <OrderStateSelect size='small' singleId={0} multipleIds={form.stateIds} onMultipleChange={(v) => handleFormChange('stateIds', v)} onSingleChange={() => null} isMultiple={true} />
            </Grid >

            <Grid item xs={12} sm={12} md={4} display="flex">
                <Box mr={2}>
                    <TextField
                        label={t('general.from')}
                        defaultValue={form.createdFrom}
                        InputLabelProps={{
                            shrink: true
                        }}
                        size={'small'}
                        onChange={(e) =>    handleFormChange('createdFrom', e.target.value)}
                        type={'date'}
                    />
                </Box>
                <Box>
                    <TextField
                        label={t('general.to')}
                        defaultValue={form.createdTo}
                        InputLabelProps={{
                            shrink: true
                        }}
                        size={'small'}
                        onChange={(e) =>    handleFormChange('createdTo', e.target.value)}
                        type={'date'}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} display="flex">
                <UsersSelect showAll={true} size="small" onChange={(v:number|null) => handleFormChange('userId', v)} id={form.userId} variant="outlined" isDisabled={false} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} display="flex">
                <ErpStoreSelect size="small" onClick={(s) => setSelectedStore(s)} id={0} variant="select" />
            </Grid>
            <Grid item xs={12} sx={{
                display:  'flex',
                justifyContent: 'left'
            }}>

                <ButtonBasic  onClick={handleFilter} appVariant="filter" type="button"
                              isLoading={isLoading}>{t('general.buttons.toFilter')}</ButtonBasic>
            </Grid>
        </Grid>
        </PaperBasic>

        <OrdersFilterTable  onClick={(id:number) => handelDetailClick(id)} rows={rows} isLoading={isLoading} />
        <OrderDetailDialog onEditOrder={ (id:number) => props.onEditOrder(id) } key={selectedOrderId} refresh={() => handleFilter()} orderId={selectedOrderId} onClose={() => setDetailOpen(false)} open={isDetailOpen} />

        </>
    )

}