import React, {useEffect, useState} from "react";
import {IOrderState} from "../../interfaces/order/IOrderState";
import {useTranslation} from "react-i18next";
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import orderService from "../../services/OrderService";

interface IProps {
    onSingleChange: (id:number) => void
    onMultipleChange: (ids:number[]) => void
    isMultiple: boolean
    size?: "small" | "medium"
    multipleIds: number[]
    singleId: number
}

export const OrderStateSelect: React.FC<IProps> = (props) => {
    const {t} = useTranslation();
    const [loaded, setLoaded] = React.useState(false);
    const [states, setStates] = useState<IOrderState[]>([]);


    const handleMultipleChange = (val: unknown) => {
        props.onMultipleChange(val as number[]);
    }

    const handleSingleChange = (val:string|number) => {
        props.onSingleChange(Number(val));

    }

    useEffect(() => {

        if (loaded) return;

        setStates(orderService.returnOrderStates());

        setLoaded(true);

    }, [loaded]);

    if (props.isMultiple) {
        return (
            <FormControl fullWidth={true}>
                <InputLabel id="state-select-label">{t('products.newProductDialog.state')}</InputLabel>
                <Select
                    variant="outlined"
                    fullWidth={true}
                    size="small"
                    labelId="state-select-label"
                    id="state-select"
                    label={t('orders.filter.state')}
                    value={props.multipleIds}
                    multiple={true}
                    input={<OutlinedInput label="Tag" />}
                    onChange={(e) => handleMultipleChange(e.target.value )}
                >
                    {states.map((x,i) => {
                        return(
                            <MenuItem key={'state_order' + i} value={x.id}>
                                {x.name}
                            </MenuItem>
                        )
                    })}

                </Select>
            </FormControl>);
    } else{
        return (
            <FormControl>
                <InputLabel id="state-select-label">{t('products.newProductDialog.state')}</InputLabel>
                <Select
                    variant="standard"
                    size="small"
                    labelId="state-select-label"
                    id="state-select"
                    label={t('orders.filter.state')}
                    value={props.singleId}
                    onChange={(e) => handleSingleChange(e.target.value)}
                >
                    {states.map((x,i) => {
                        return(
                            <MenuItem key={'state_order' + i} value={x.id}>{x.name} </MenuItem>
                        )
                    })}

                </Select>
            </FormControl>

        );
    }

}