import React, {useEffect} from "react";
import {Button, CircularProgress, FormControl, FormControlLabel, Grid, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {IProduct} from "../../../interfaces/product/IProduct";
import {ButtonBasic} from "../../shared/ButtonBasic";
import ProducerAllSelect from "../../producer/ProducerAllSelect";
import UnitSelect from "../../unit/UnitSelect";
import {AlertAppContext} from "../../alert/AlertAppContext";
import ProductLocale from "../../../models/product/ProductLocale";
import productService from "../../../services/ProductService";
import {CategoryTreeSelected} from "../../category/CategoryTreeSelected";
import {IProductBasicInfo} from "../../../interfaces/product/basicInfo/IProductBasicInfo";
import NewProductCategoryTreeModal from "../NewProductCategoryTreeModal";
import categoryService from "../../../services/CategoryService";
import {Product} from "../../../models/product/Product";
import {IBasicException} from "../../../interfaces/IBasicException";
import CategoryIcon from "@mui/icons-material/Category";
import {ProductState} from "../ProductState";
import Switch from "@mui/material/Switch";
import {NullableString} from "../../../interfaces/NullableStringType";

interface IProps {
    product: IProduct;
    onClose:  () => void;
}

const BasicInfoTab: React.FC<IProps> = (props) => {
    const {t, i18n} = useTranslation();

    const [loaded, setLoaded] = React.useState(false);

    const appAlert = React.useContext(AlertAppContext);

    const [product, setProduct] = React.useState<IProductBasicInfo|null>(null);

    const [producerId, setProducerId] = React.useState<number>(props.product.producerId);

    const [basicUnitId, setBasicUnitId] = React.useState<number>(props.product.basicUnitId);

    const [showInCatalogue, setShowInCatalogue] = React.useState<boolean>(props.product.showInPublicCatalogue);

    const [stateId, setStateId] = React.useState<number>(props.product.stateId);

    const [ean, setEan] = React.useState<NullableString>(props.product.ean);

    const [name, setname] = React.useState<string>(props.product.name);

    const [codeSuffix] = React.useState<string|null>(props.product.codeSuffix);

    const [producerCode, setProducerCode] = React.useState<string|null>(props.product.producerCode);

    const [actionLoading, setActionLoading] = React.useState<boolean>(false);

    const [isCatModalOpen ,setIsCatModalOpen] = React.useState<boolean>(false);

    const [catgoryId, setCategoryId] = React.useState<number>(props.product.categoryId);
    const [categoryTree, setCategoryTree] = React.useState<string[]>([]);
    const [categoryMatrix, setCategoryMatrix] = React.useState<number[]>([]);

    const handleChooseCategory = (names: string[], id: number, matrix: number[]) => {
        setCategoryId(id);
        setCategoryTree(names);
        setCategoryMatrix(matrix);
        setIsCatModalOpen(false);
    }

    const handleSave = () => {

        if(!product) return;

        setActionLoading(true);



        let p = new Product({
            id: product.id,
            basicCode: product.basicCode,
            producerCode: producerCode,
            codeSuffix: codeSuffix,
            name: name,
            basicUnitId: basicUnitId,
            producerId: producerId,
            categoryId: catgoryId,
            mainPhotoId: props.product.mainPhotoId,
            stateId: stateId,
            showInPublicCatalogue: showInCatalogue,
            ean: ean
        });

        let valid = p.validate(true);
        if(!valid.isValid){
            appAlert.showAlert({severity: "warning", message: valid.message});
            return false;
        }

        let pl = new ProductLocale({
            id: 0, //aby zalozil pouze product
            product: p,
            langCode: i18n.language, //nezbytne pro navraceni zalozeneho produktu
            longHtmlDescription: "",
            name: "",
            productId: p.id,
            shortDescription: ""
        })

        productService.update(pl).then(() => {
            appAlert.showAlert({severity: "success" });
            //props.onClose();

        }).catch((err:IBasicException) => {
           appAlert.showAlert({severity: "error", message: err.message });
        }).finally(() => {
            setActionLoading(false);
        });



    }

    useEffect(() => {
        if(!loaded){
            productService.getBasicInfo(props.product.id, i18n.language).then((res) => {
                console.log(res);
                setProduct(res);
                setStateId(res.stateId);
                setBasicUnitId(res.basicUnitId);
                setProducerId(res.producerId);
                setname(res.name);
                setCategoryId(res.categoryId);
                setCategoryTree(categoryService.returnCategoryTree(res.category.stack));
                setCategoryMatrix(categoryService.returnCategoryMatrix(res.category.stack));
                setEan(res.ean);
            }).finally(() => setLoaded(true));
        }
    }, [i18n.language, loaded, props.product.id]);



    if(!loaded ){
        return(
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
            </Grid>
        )
    }

    if(!product) return null;


    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={3} md={2}>
                    <TextField
                        label={'ID'}
                        value={product.id}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={9} md={10} />
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth={true}>
                        <TextField
                            label={t('products.newProductDialog.productInternalTitle')}
                            fullWidth={true}
                            value={name}
                            inputProps={{maxLength: 200}}
                            variant="standard"
                            onChange={(e) => setname(e.target.value)}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={3} md={2}>
                    <TextField
                        label={t('products.newProductDialog.basicCode')}
                        value={product.basicCode}
                        variant="standard"
                        aria-readonly={true}

                    />
                </Grid>
                <Grid item xs={4} md={3}>
                    <TextField
                        label={t('products.newProductDialog.codeSuffix')}
                        value={codeSuffix}
                        variant="standard"
                        //onChange={(e) => setCodeSuffix(e.target.value)}
                        aria-readonly={true}
                    />
                </Grid>

                {/*   PRODUCER    */}

                <Grid item xs={12} md={6}>
                    <ProducerAllSelect selectedId={producerId} onChange={(id) => setProducerId(id)} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        label={t('products.newProductDialog.producerCode')}
                        value={producerCode}
                        variant="standard"
                        onChange={(e) => setProducerCode(e.target.value)}
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        label="EAN"
                        value={ean}
                        variant="standard"
                        onChange={(e) => setEan(e.target.value)}
                        inputProps={{ maxLength: 13 }}
                        fullWidth={true}

                    />
                </Grid>

                {/*  BASIC UNIT */}

                <Grid item xs={3}  >
                    <UnitSelect isDisabled={true} variant="standard" onChange={(val) => setBasicUnitId(val)} id={basicUnitId} />
                </Grid>

                {/* ----- STATE ----  */}
                <Grid item xs={3}>
                    <ProductState chip={false} multiple={false} onSingleChange={(val) => setStateId(val)} onMultipleChange={(val) => null} multipleIds={[]} singleId={stateId} disabled={false} />
                </Grid>

                {/* ----- SHOW IN PUBLIC KATALOGUE -------  */}

                <Grid item xs={3} >
                    <FormControlLabel
                        value="top"
                        control={  <Switch
                            checked={showInCatalogue}
                            onChange={() => setShowInCatalogue(!showInCatalogue)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />}
                        label="Zobraz v katalogu"
                        labelPlacement="end"
                    />

                </Grid>

                {/* ----- CATEGORY -------  */}

              {/*  <Grid item xs={12}>
                    <FormChip label={t('general.text.category')} />
                </Grid>
*/}
                <Grid item xs={12}>
                   <CategoryTreeSelected tree={categoryTree} showText={true} />
                </Grid>
                <Grid item xs={12} >
                    <Button onClick={() => setIsCatModalOpen(true)} variant="outlined" color="secondary" startIcon={<CategoryIcon />}>{t('general.text.categoryTree')}</Button>
                </Grid>


                <Grid item xs={12} />

                {/*  FOOTER BUTTONS */}

                <Grid item xs={12}>
                    <ButtonBasic appVariant="save" onClick={handleSave} isLoading={actionLoading} />
                </Grid>
            </Grid>
                <NewProductCategoryTreeModal tree={categoryTree} showEditButton={false} matrix={categoryMatrix} onClose={() => setIsCatModalOpen(false)} open={isCatModalOpen} onCatSelect={handleChooseCategory} />
        </>
    )
}

export default BasicInfoTab;