import {IProduct} from "../../interfaces/product/IProduct";
import {NullableString} from "../../interfaces/NullableStringType";
import {IValid} from "../../interfaces/IValid";
import {IMainPhoto} from "../../interfaces/product/IMainPhoto";
import {MainProductPhoto} from "./MainProductPhoto";



export class Product implements IProduct{
    id: number
    name: string
    basicCode: string
    codeSuffix: NullableString
    producerCode: NullableString
    categoryId: number
    producerId: number
    basicUnitId: number
    mainPhotoId: number
    mainPhoto?: IMainPhoto | null
    stateId: number
    showInPublicCatalogue: boolean
    ean: NullableString




    constructor(json:IProduct) {
        this.id = json.id;
        this.basicCode = json.basicCode;
        this.name = json.name;
        this.codeSuffix = json.codeSuffix ?? null;
        this.producerCode = json.producerCode ?? null;
        this.categoryId = json.categoryId;
        this.producerId = json.producerId;
        this.basicUnitId = json.basicUnitId;
        this.mainPhotoId = json.mainPhotoId ?? 0;
        this.mainPhoto = json.mainPhoto ? new MainProductPhoto(json.mainPhoto) : null;
        this.stateId = json.stateId;
        this.showInPublicCatalogue = json.showInPublicCatalogue;
        this.ean = json.ean;
    }




    validate(isEdit:boolean):IValid {
        let v:IValid = {
            isValid: false,
            message: ""
        }

        if(this.name === "" || this.name === null || this.name === undefined){
            v.message = "Vyplňte název produktu."
            return v;
        }

        if(this.producerId === 0){
            v.message = "Vyberte výrobce."
            return v;
        }

        if(this.categoryId === 0){
            v.message = "Vyberte kategorii."
            return v;
        }

        if(!isEdit){
            //v pripade noveho produktu pokud mam vybrany basic code to jasne z logiky veci pridavam variantu produktu a pak nemuze byt codeSuffix prazdny
            if(this.basicCode !== null && this.codeSuffix === null){
                v.message = "Vypadá to, že se snažíte přidat variantu produktu, v toto případě nemůže být suffix kódu prázdný."
            }
        }



        v.isValid = true;
        return v;
    }




}

